
/* custom application styles go here*/
.ctek-menu {
	font-size: 14px!important;
	font-family: "myriad-pro", "myriad pro", arial, sans-serif!important;
	font-weight:300;
}
#corporatek_header_logo{
	width:210px;
}

.ctek-menu-demo{
	background-color: #1A5686;
	padding: 6px 11px!important;
	border: solid #1A5686 2px!important;
}

.ctek-submenu {
	cursor: pointer;
}
.ctek-blue {
	color: #8AB7E4; 
	font-size: 16px; 
	line-height: 19px;
}
.ctek-bcrumb {
	padding-top:100px;
	z-index:9999999;	

}
.ctek-col-md-2 {
	padding-right: 0!important;
	padding-left:0!important;
}
.ctek-submenu img {
 filter: url("data:image/svg+xml;
utf8, <svg xmlns='http://www.w3.org/2000/svg'> <filter id='grayscale'> <feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/> </filter> </svg>#grayscale");
	/* Firefox 10+, Firefox on Android */
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	/* IE 6-9 */
	-moz-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}
.ctek-submenu:hover img {
 filter: url("data:image/svg+xml;
utf8, <svg xmlns='http://www.w3.org/2000/svg'> <filter id='grayscale'> <feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/> </filter> </svg>#grayscale");
	-webkit-filter: none;
	filter: none;
}
.primary {
	cursor: pointer;
}
.ctek-submenu-selected img {
 filter: url("data:image/svg+xml;
utf8, <svg xmlns='http://www.w3.org/2000/svg'> <filter id='grayscale'> <feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/> </filter> </svg>#grayscale");
	-webkit-filter: none;
	filter: none;
}
.language_switch {
	position: absolute;
	font-size: 11px;
	left: 95%;
	top: -16px;
	z-index: 99999;
}
.last-item {
    color: #586066!important;
	pointer-events: none;
}
.products_logo {
	margin-top: 30px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
}
.smallmenuhide #menu-button:after {
	display:none!important;
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
#my-content {
	display: block;
}
/* show it on small screens */
}

@media screen and (min-width: 1025px) and (max-width: 2048px) {
#my-content {
	display: none;
}
/* hide it elsewhere */
}

@media screen and (min-width: 0px) and (max-width: 900px) {
.ctek-product-image {
	background: none!important;
}
}

#code_of_conduct li {
	margin-bottom: 20px;
}
/* Fixed footer.
* --------------------------------------- */
#footer{
	z-index:999;
	position:fixed;
	display:block;
	width: 100%;
	text-align:center;
	bottom:0px;
}

@media screen and (max-width: 767px) {
	#footer {
		position:relative;
	}
}


.client-logo {
	max-height: 80px;
	width: auto !important;
}

@media screen and (min-width: 480px) {
	.client-logo {
		max-height: 100px;
		width: auto !important;
	}
}

@media screen and (min-width: 920px) {
	.client-logo {
		max-height: 130px;
		width:auto!important;
	}

}
.langlist .current {
	font-weight: bold;
}

